import React from "react"
import "./TeamCard.scss"
import Animation from "@components/elements/Animation"
import { isEmpty } from "lodash"
import { Link } from "gatsby"
import { teamsURL } from "@lib/urls"
import GGFXImage from "@components/elements/GGFXImage"

const TeamCard = ({ team, noAnimation }) => {
  if (isEmpty(team)) return null

  const { name, designation, image, slug, ggfx_results, strapi_id } = team

 
  return (
    <Animation
      wrapperClassName="team-card-wrap"
      className="team-card"
      noAnimation={noAnimation}
    >
      <Link to={`${teamsURL + slug}/`} className="img-section img-zoom">
        <GGFXImage
          ImageSrc={image}
          altText={name}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="team.image.tile_img"
          strapiID={strapi_id}
        />
      </Link>
      <p className="name">{name}</p>
      <p className="designation">{designation}</p>
    </Animation>
  )
}

export default TeamCard
